<template>
    <div class="row">
        <div class="col-12 px-0 mb-2" v-if="type == 'V' && indicator.V != 0">
            <div class="table-responsive">
                <table class="table text-black">
                    <tr>
                        <td colspan="2"><b>Visual</b></td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            Pemelajar visual mampu belajar dalam kondisi terbaik melalui menggambar, melihat gambar, atau ‘melihat’ berbagai hal ketika ia membaca, menulis, dan menyimak.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            Menggunakan Gaya Belajarmu
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Untuk membaca/belajar &rarr;
                        </td>
                        <td width="70%">
                            <ul>
                                <li>
                                    Menggambar sketsa atau menggambar diagram konsep.
                                </li>
                                <li>
                                    Menggunakan stabilo berwarna untuk menandai apa yang ingin diingat.
                                </li>
                                <li>
                                    Mencatat judul dalam teks, dan melihat diagram, grafik, peta, gambar, dan benda visual lainnya.
                                </li>
                                <li>
                                    Menulis simbol yang bermakna sesuatu di pinggiran halaman. (Sebagai contoh, memberi simbol tanda seru sebagai penanda untuk informasi yang paling penting di Bab.)
                                </li>
                                <li>
                                    Membuat sendiri alur grafik atau alur waktu kerja.
                                </li>
                                <li>
                                    Membuat outline (skema) dengan tinta warna yang berbeda.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Untuk menulis &rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Menggunakan pemetaan atau pengelompokkan untuk mendapatkan ide.
                                </li>
                                <li>
                                    Menggunakan grafik atau outline (skema) untuk merencanakan, menulis dan merevisi.
                                </li>
                                <li>
                                    Menggunakan koreksi simbol untuk memperbaiki.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Untuk mengikuti tes &rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Menyoroti informasi penting, atau memberi centang atau simbol
                                </li>
                                <li>
                                    Membuat alur grafik atau outline (skema) dalam memberikan jawaban.
                                </li>
                            </ul>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="col-12 px-0 mb-2" v-if="type == 'A' && indicator.A != 0">
            <div class="table-responsive">
                <table class="table text-black">
                    <tr>
                        <td colspan="2"><b>Auditory</b></td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            Pemelajar auditory mampu belajar dalam kondisi terbaik dengan menyimak segala hal.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            Menggunakan Gaya Belajarmu
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Untuk membaca/belajar &rarr;
                        </td>
                        <td width="70%">
                            <ul>
                                <li>
                                    Membaca keras catatan, teks, bahan materi dan sebagainya.
                                </li>
                                <li>
                                    Merekam pembelajaran dan diskusi kelas (tapi tidak lupa untuk mencatatnya juga). Kemudian, kamu dapat mendengarkan rekamannya.
                                </li>
                                <li>
                                    Menyimak kepada teman-temannya tentang materi ajar.
                                </li>
                                <li>
                                    Bekerja dengan teman-teman lainnya untuk persiapan kelas, melengkapi tugas dan sebagainya.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Untuk menulis &rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Mendapat ide dengan berbicara pada diri sendiri atau ke orang lain.
                                </li>
                                <li>
                                    Membaca tulisannya dengan keras ketika membuat draft.
                                </li>
                                <li>
                                    Membaca tulisannya dengan keras ketika merevisi dan memperbaiki tulisan (edit).
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Untuk mengikuti tes &rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Membaca petunjuk atau arahan dan soal tes dengan bersuara dalam bisikan kecil.
                                </li>
                                <li>
                                    Membaca jawaban tes dengan bersuara dalam bisikan kecil.
                                </li>
                            </ul>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="col-12 px-0 mb-2" v-if="type == 'R' && indicator.R != 0">
            <div class="table-responsive">
                <table class="table text-black">
                    <tr>
                        <td colspan="2"><b>Read/Write</b></td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            Pemelajar read/write mampu belajar dalam kondisi terbaik melalui membaca dan menulis sepanjang pelajaran.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            Menggunakan Gaya Belajarmu
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Untuk membaca/belajar &rarr;
                        </td>
                        <td width="70%">
                            <ul>
                                <li>
                                    Membaca judul utama, rangkuman, dan pertanyaan yang ada di dalam buku.
                                </li>
                                <li>
                                    Mengatakan apa yang dibaca ke dalam kata-kata sendiri.
                                </li>
                                <li>
                                    Membuat catatan dengan hati-hati dari buku dan pembelajaran, dan membacanya lagi kemudian.
                                </li>
                                <li>
                                    Menjaga dan membaca semua bahan ajar.
                                </li>
                                <li>
                                    Menyoroti (highlight) ketika membaca.
                                </li>
                                <li>
                                    Menggambarkan grafik, diagram, peta dan bentuk visual lainnya dalam tulisan
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Untuk menulis &rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Menulis bebas atau bertukar pikiran untuk mendapatkan ide.
                                </li>
                                <li>
                                    Membaca atau baca ulang apa yang ditulis, membuat catatan untuk revisi.
                                </li>
                                <li>
                                    Merevisi tulisannya beberapa kali.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Untuk mengikuti tes &rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Membaca dan menyoroti petunjuk atau perintah tes.
                                </li>
                                <li>
                                    Menulis outline (skema) untuk pertanyaan esai, atau menulis dengan cepat dan merevisinya.
                                </li>
                                <li>
                                    Membaca ulang jawaban dengan hati-hati.
                                </li>
                            </ul>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="col-12 px-0 mb-2" v-if="type == 'K' && indicator.K != 0">
            <div class="table-responsive">
                <table class="table text-black">
                    <tr>
                        <td colspan="2"><b>Kinesthetic</b></td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            Pemelajar kinestetik mampu belajar dalam kondisi terbaik dengan melakukan sesuatu dan bergerak atau berkeliling.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            Menggunakan Gaya Belajarmu
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Untuk membaca/belajar &rarr;
                        </td>
                        <td width="70%">
                            <ul>
                                <li>
                                    Berdiri ketika membaca atau belajar
                                </li>
                                <li>
                                    Mengambil rehat sejenak dan berjalan berkeliling.
                                </li>
                                <li>
                                    Menggarisbawahi atau menyoroti bacaan, atau membuat catatan—yang membuatnya melakukan sesuatu.
                                </li>
                                <li>
                                    Membuat flash card untuk memelajari materi ajar.
                                </li>
                                <li>
                                    Membuat puzzle, seperti teka-teki silang, untuk membantunya mengingat konsep penting.
                                </li>
                                <li>
                                    Membuat buku pedoman belajar sendiri.
                                </li>
                                <li>
                                    Menghubungkan informasi ke pengalaman pribadi.
                                </li>
                                <li>
                                    Menandai contoh pada teks yang relevan dengannya.
                                </li>
                                <li>
                                    Menulis pertanyaan yang ia miliki, dan menanyakannya.
                                </li>
                                <li>
                                    Bekerja dengan teman-teman lainnya untuk persiapan kelas dan melengkapi tugas.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Untuk menulis &rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Membayangkan topiknya seperti suatu film untuk mendapatkan ide.
                                </li>
                                <li>
                                    Berpikir tentang suatu ide untuk menulis ketika ia berjalan.
                                </li>
                                <li>
                                    Membayangkan gambar apa yang dapat mengekspresikan idenya.
                                </li>
                                <li>
                                    Menulis ide atau detail untuk makalah pada sticky notes, dan membawa catatannya itu sambil berkeliling.
                                </li>
                                <li>
                                    Menulis dan bertanya tentang topik dirinya.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Untuk mengikuti tes &rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Tarik nafas dalam-dalam dan berulang-ulang sepanjang tes.
                                </li>
                                <li>
                                    Berdiri dan berjalan ke tempat di salah satu bagian ruangan yang berbeda (setelah meminta izin kepada pengawasnya apabila diperbolehkan).
                                </li>
                                <li>
                                    Mengkalkulasikan waktu yang ia akan habiskan pada masing-masing bagian tes, dan menghitung waktunya sendiri.
                                </li>
                                <li>
                                    Berdiri dan mengambil nafas dalam-dalam ketika ia meninjau jawabannya.
                                </li>
                            </ul>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['type', 'indicator']
}
</script>
<style scoped>
ul {
    padding-left: 20px;
}
ul li {
    list-style-type: disc !important;
}
</style>