<template>
    <div class="row">
        <div class="col-12" v-if="!isLoad">
            <div v-if="exist" class="row">
                <div class="col-12" v-if="vark != null">
                    <div class="py-5 card bg-white border-0 shadow-xss rounded-lg overflow-hidden">
                        <div class="d-flex flex-row  border-0 w-100 p-0 mb-3 shadow-none">
                            <div class="mx-4 my-1 p-0 ml-1 btn-round-md rounded-xl bg-current">
                                <i class="fas fa-clipboard-check font-md text-white"></i>
                            </div>
                            <div class="card-body p-0 pb-2 border-bottom border-dark">
                                <div class="row justify-content-between col-md-12">
                                    <h6 class="fw-700 text-grey-700 font-xssss">{{vark.created.name}}</h6>
                                </div>
                                <div class="row justify-content-between col-md-12">
                                    <h2 class="font-lg text-grey-900 fw-600">{{vark.title}}</h2>
                                </div>
                            </div>
                        </div>
                        <div class="col-11 ml-5 pl-5">
                            <div class="row">
                                <div class="col-12">
                                    <h4 class="text-grey-800 font-xs mt-4 fw-700">Description :</h4>
                                    <p class="font-xsss fw-600 lh-28 text-grey-600 mb-0 pl-0">{{vark.description}}</p>
                                    <div class="float-right">
                                        <router-link v-if="vark.recent_submission" :to="{name:'GettingStartedVarkRecent', params:{idVark: vark.id}}" class="bg-primary border-0 text-white fw-600 text-uppercase font-xssss float-left rounded-lg d-inline-block mt-4 p-2 lh-34 text-center ls-3 w200">Recent : {{vark.recent_submission}}</router-link>
                                        <router-link v-if="!vark.own_submission" :to="{name:'GettingStartedVarkStart', params:{idvark: vark.id}}" class="bg-current border-0 text-white fw-600 text-uppercase font-xssss float-left rounded-lg d-inline-block mt-4 p-2 lh-34 text-center ls-3 w200 ml-3">Start</router-link>
                                        <div v-else>
                                            <div class="bg-current border-0 text-white fw-600 text-uppercase font-xssss float-left rounded-lg d-inline-block mt-4 p-2 lh-34 text-center ls-3 w150 mr-2">Finished</div>
                                            <!-- <div class="bg-success border-0 text-white fw-600 text-uppercase font-xssss float-left rounded-lg d-inline-block mt-4 p-2 lh-34 text-center ls-3 w150 mr-2">Score : {{ vark.own_submission.score }}</div> -->
                                        </div>
                                    </div>
                                </div>
                                <div v-if="vark.own_submission" class="col-12 mt-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <apexchart type="bar" :options="content.options" :series="content.series"></apexchart>
                                                </div>
                                                <div class="col-lg-6">
                                                    <h4 class="text-grey-800 font-xs mt-4 mb-3 fw-700">Indicator :</h4>
                                                    <p class="mb-1" v-for="(item, index) in vark.own_submission.indicator" :key="index">The number of searches for the circled <b>{{ index }}</b> answers = <b>{{ item }}</b></p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12" v-for="(item, index) in vark.own_submission.study_type" :key="index">
                                                    <LearningStyle :type="item" :indicator="vark.own_submission.indicator"></LearningStyle>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12" v-else>
                    <div class="card w-100 p-3 bg-grey p-lg-3 border-0 mb-3 rounded-lg d-block float-left">
                        <div class="row">
                            <div class="col-12 text-center py-3">
                                <img :src="'/images/empty.png'" alt="" width="300">
                                <h1 class="text-muted">Data don't exist</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="row">
                <div class="col-12">
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-8 text-center default-page">
                            <div class="card border-0 text-center d-block">
                                <img :src="'/images/404.png'" alt="icon" class="w300 mb-4 ml-auto mr-auto ">
                                <h1 class="fw-700 text-grey-900 display4-size display4-md-size">Page Not Found.</h1>
                                <p class="text-grey-500 font-xss">The page you're looking for isn't available. Try to search again or use the go to.</p>
                                <button @click="$router.go(-1)" class="p-3 border-0 w175 bg-current text-white d-inline-block text-center fw-600 font-xssss rounded-lg text-uppercase ls-3">Return Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12" v-else>
            <div class="row">
                <div class="col-12 py-3 text-center">
                    <div class="my-3">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import LearningStyle from './LearningStyle.vue'
export default {
    data() {
        return {
            idVark: this.$route.params.idVark,
            isLoad: true,
            exist: true,
            vark: {},
            content : {
                options: {
                    noData: {  
                        text: "Loading...",  
                        align: 'center',  
                        verticalAlign: 'middle',  
                        offsetX: 0,  
                        offsetY: 0,  
                        style: {  
                            color: "#000000",  
                            fontSize: '14px',  
                            fontFamily: "Helvetica"  
                        }  
                    },
                },
                series: [],
            },
        }   
    },
    components: {
        LearningStyle
    },
    created(){
        this.getVark()
    },
    methods:{
        async getVark(){
            await axios.get(`${process.env.VUE_APP_URL_API}/core/getting-start/vark/detail?id=${this.idVark}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if (res.data.success) {
                    this.vark = res.data.data
                    if(this.vark.own_submission) {
                        this.content = this.vark.own_submission.chart
                    }
                    this.isLoad = false
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Vark Test!',
                        text: res.data.message,
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            }).catch(err => {
                if (err.response.status == 404) {
                    this.isLoad = false
                    this.exist = false
                }
            })
        }
    },
}
</script>